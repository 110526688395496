import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e7e94d16 = () => interopDefault(import('../src/pages/personal-data/index.vue' /* webpackChunkName: "pages/personal-data/index" */))
const _2fe91e83 = () => interopDefault(import('../src/pages/personal-data/processing/index.vue' /* webpackChunkName: "pages/personal-data/processing/index" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7e2e32d0 = () => interopDefault(import('../src/pages/print/_slug/mortgage.vue' /* webpackChunkName: "pages/print/_slug/mortgage" */))
const _3f1fdda0 = () => interopDefault(import('../src/pages/project/_slug/about/index.vue' /* webpackChunkName: "pages/project/_slug/about/index" */))
const _2ba3cf41 = () => interopDefault(import('../src/pages/project/_slug/about/index/_category/index.vue' /* webpackChunkName: "pages/project/_slug/about/index/_category/index" */))
const _043af7e0 = () => interopDefault(import('../src/pages/project/_slug/about/index/_category/_subcategory.vue' /* webpackChunkName: "pages/project/_slug/about/index/_category/_subcategory" */))
const _b29ba7c0 = () => interopDefault(import('../src/pages/project/_slug/company/index.vue' /* webpackChunkName: "pages/project/_slug/company/index" */))
const _60613f7a = () => interopDefault(import('../src/pages/project/_slug/favorites/index.vue' /* webpackChunkName: "pages/project/_slug/favorites/index" */))
const _66bb5d06 = () => interopDefault(import('../src/pages/project/_slug/flats/index.vue' /* webpackChunkName: "pages/project/_slug/flats/index" */))
const _1252fe70 = () => interopDefault(import('../src/pages/project/_slug/location/index.vue' /* webpackChunkName: "pages/project/_slug/location/index" */))
const _9b7cb744 = () => interopDefault(import('../src/pages/project/_slug/meeting/index.vue' /* webpackChunkName: "pages/project/_slug/meeting/index" */))
const _3bccec61 = () => interopDefault(import('../src/pages/project/_slug/meeting/index/index.vue' /* webpackChunkName: "pages/project/_slug/meeting/index/index" */))
const _32977f5f = () => interopDefault(import('../src/pages/project/_slug/meeting/index/list.vue' /* webpackChunkName: "pages/project/_slug/meeting/index/list" */))
const _1f404ca4 = () => interopDefault(import('../src/pages/project/_slug/meeting/index/stats.vue' /* webpackChunkName: "pages/project/_slug/meeting/index/stats" */))
const _328ced40 = () => interopDefault(import('../src/pages/project/_slug/progress/index.vue' /* webpackChunkName: "pages/project/_slug/progress/index" */))
const _7f50de8e = () => interopDefault(import('../src/pages/project/_slug/flats/chessboard/index.vue' /* webpackChunkName: "pages/project/_slug/flats/chessboard/index" */))
const _d15eb830 = () => interopDefault(import('../src/pages/project/_slug/flats/visual/index.vue' /* webpackChunkName: "pages/project/_slug/flats/visual/index" */))
const _9ea50b92 = () => interopDefault(import('../src/pages/project/_slug/meeting/finish.vue' /* webpackChunkName: "pages/project/_slug/meeting/finish" */))
const _0bfbd966 = () => interopDefault(import('../src/pages/project/_slug/purchase/mortgage/index.vue' /* webpackChunkName: "pages/project/_slug/purchase/mortgage/index" */))
const _c2673430 = () => interopDefault(import('../src/pages/project/_slug/flats/_id/index.vue' /* webpackChunkName: "pages/project/_slug/flats/_id/index" */))
const _639a87fa = () => interopDefault(import('../src/pages/project/_slug/meeting/_step/index.vue' /* webpackChunkName: "pages/project/_slug/meeting/_step/index" */))
const _013fac64 = () => interopDefault(import('../src/pages/project/_slug/flats/_id/LotDetailWidgetShell.vue' /* webpackChunkName: "pages/project/_slug/flats/_id/LotDetailWidgetShell" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact',
  scrollBehavior,

  routes: [{
    path: "/personal-data",
    component: _e7e94d16,
    name: "personal-data"
  }, {
    path: "/personal-data/processing",
    component: _2fe91e83,
    name: "personal-data-processing"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }, {
    path: "/print/:slug?/mortgage",
    component: _7e2e32d0,
    name: "print-slug-mortgage"
  }, {
    path: "/project/:slug?/about",
    component: _3f1fdda0,
    name: "project-slug-about",
    children: [{
      path: ":category",
      component: _2ba3cf41,
      name: "project-slug-about-index-category"
    }, {
      path: ":category/:subcategory?",
      component: _043af7e0,
      name: "project-slug-about-index-category-subcategory"
    }]
  }, {
    path: "/project/:slug?/company",
    component: _b29ba7c0,
    name: "project-slug-company"
  }, {
    path: "/project/:slug?/favorites",
    component: _60613f7a,
    name: "project-slug-favorites"
  }, {
    path: "/project/:slug?/flats",
    component: _66bb5d06,
    name: "project-slug-flats"
  }, {
    path: "/project/:slug?/location",
    component: _1252fe70,
    name: "project-slug-location"
  }, {
    path: "/project/:slug?/meeting",
    component: _9b7cb744,
    children: [{
      path: "",
      component: _3bccec61,
      name: "project-slug-meeting-index"
    }, {
      path: "list",
      component: _32977f5f,
      name: "project-slug-meeting-index-list"
    }, {
      path: "stats",
      component: _1f404ca4,
      name: "project-slug-meeting-index-stats"
    }]
  }, {
    path: "/project/:slug?/progress",
    component: _328ced40,
    name: "project-slug-progress"
  }, {
    path: "/project/:slug?/flats/chessboard",
    component: _7f50de8e,
    name: "project-slug-flats-chessboard"
  }, {
    path: "/project/:slug?/flats/visual",
    component: _d15eb830,
    name: "project-slug-flats-visual"
  }, {
    path: "/project/:slug?/meeting/finish",
    component: _9ea50b92,
    name: "project-slug-meeting-finish"
  }, {
    path: "/project/:slug?/purchase/mortgage",
    component: _0bfbd966,
    name: "project-slug-purchase-mortgage"
  }, {
    path: "/project/:slug?/flats/:id",
    component: _c2673430,
    name: "project-slug-flats-id"
  }, {
    path: "/project/:slug?/meeting/:step",
    component: _639a87fa,
    name: "project-slug-meeting-step"
  }, {
    path: "/project/:slug?/flats/:id/LotDetailWidgetShell",
    component: _013fac64,
    name: "project-slug-flats-id-LotDetailWidgetShell"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
