import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('s-select', {
    attrs: {
      "value": _vm.computedValue,
      "theme": _vm.theme,
      "display": _vm.display,
      "size": _vm.size,
      "z-index": _vm.zIndex,
      "scrollable-parents-selector": "[data-scrollable-container]",
      "label": _vm.label,
      "placeholder": _vm.placeholder,
      "disabled": _vm.isSelectDisabled,
      "multiple": _vm.multiple
    },
    on: {
      "input": _vm.handleChange
    }
  }, [_vm.isValueAny ? _c('s-option', {
    attrs: {
      "force-clear": "",
      "label": _vm.valueAnyLabel,
      "disabled": _vm.loading
    }
  }, [_vm._v("\n    " + _vm._s(_vm.valueAnyLabel) + "\n  ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.availableOptions, function (option) {
    return _c('s-option', {
      key: "option-".concat(_vm.getOptionLabel(option), "-").concat(_vm.getOptionValue(option)),
      attrs: {
        "value": _vm.getOptionValue(option),
        "label": _vm.getOptionLabel(option),
        "disabled": _vm.loading || _vm.checkDisabledOption(option)
      }
    }, [_vm._v("\n    " + _vm._s(_vm.getOptionLabel(option)) + "\n  ")]);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };